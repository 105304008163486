import { Injectable } from '@angular/core';
import {_User} from '../model/_User';
import {ApiService} from './api.service';
import {LocalStorageService} from './local-storage.service';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public user: _User;

  constructor(private readonly api: ApiService, private readonly storage: LocalStorageService, private readonly router: Router) {
  }

  public async login(user?: _User): Promise<boolean>{
    /*this.user = user;
    return this.keepSession();*/
    this.user = (user)? await this.api.loginUser(user).toPromise() : JSON.parse(await this.storage.getItem('user'));
    return (this.user!=null)? await this.keepSession() : false;
  }

  public async logout(){
    await this.storage.removeItem('user');
    this.user=null;
    this.router.navigate(['']);
  }

  public isLogged(): boolean{
    return !!this.user;
  }

  private keepSession(): Promise<boolean>{
    return this.storage.setItem('user',JSON.stringify(this.user));
  }

}
