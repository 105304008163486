import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {_User} from '../model/_User';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {LinxLink} from '../model/LinxLink';
import {SearchModel} from '../model/SearchModel';
import {DatePipe} from '@angular/common';
import {Command188} from '../model/commands/Command188';
import {Command190} from '../model/commands/Command190';
import {Command200} from '../model/commands/Command200';
import {Linx} from '../model/Linx';
import {PCommand} from '../model/commands/PCommand';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private readonly http: HttpClient, private readonly datePipe: DatePipe) {
  }

  public loginUser(user: _User): Observable<_User> {
    return this.http.put<_User>(`${environment.serverUrl}user`, user);
  }

  public getLast50LinxData(): Observable<LinxLink[]> {
    return this.http.get<LinxLink[]>(`${environment.serverUrl}linx/latest`);
  }

  public getLinxObjectsFromApi(): Observable<Linx[]>{
    return this.http.get<Linx[]>(`${environment.serverUrl}linx/`);
  }

  public getAllPendingCommands(): Observable<PCommand[]>{
    return this.http.get<PCommand[]>(`${environment.serverUrl}command/pending`);
  }
  /*public getFilteredData(dateIni: string, dateEnd: string, maxData: number,type: string, nMem: number): Observable<LinxLink[]>{
    return this.http.get<LinxLink[]>(`${environment.serverUrl}linx/${dateIni}/${dateEnd}/${maxData}/${type}/${nMem}`);
  }*/

  public getFilteredData(searchOptions: SearchModel): Observable<LinxLink[]> {
    //formatar fechas a string para que el servidor pueda interpretarlas
    const dateIni: string = this.datePipe.transform(searchOptions.dateIni, 'yyyy-MM-ddTHH:mm:ss');
    const dateEnd: string = this.datePipe.transform(searchOptions.dateEnd, 'yyyy-MM-ddTHH:mm:ss');
    if (searchOptions.type === 'PIN' || searchOptions.type === 'BTI') {
      //console.log(`${environment.serverUrl}linx/${searchOptions.type}/${dateIni}/${dateEnd}`);
      return this.http.get<LinxLink[]>(`${environment.serverUrl}linx/${searchOptions.type}/${dateIni}/${dateEnd}`);
    } else {
      //console.log(`${environment.serverUrl}linx/${searchOptions.type}/${dateIni}/${dateEnd}/${searchOptions.nMemIni}/${searchOptions.nMemEnd}`);
      return this.http.get<LinxLink[]>(`${environment.serverUrl}linx/${searchOptions.type}/${dateIni}/${dateEnd}/${searchOptions.nMemIni}/${searchOptions.nMemEnd}`);
    }
  }

  public sendCommand188(command: Command188): Observable<boolean> {
    return this.http.put<boolean>(`${environment.serverUrl}command/188`, command);
  }

  public sendCommand189(nMem: number): Observable<Command188> {
    return this.http.get<Command188>(`${environment.serverUrl}command/189/${nMem}`);
  }

  public sendCommand190(command: Command190): Observable<boolean> {
    return this.http.put<boolean>(`${environment.serverUrl}command/190`, command);
  }

  public sendCommand191(): Observable<Command190> {
    return this.http.get<Command190>(`${environment.serverUrl}command/191`);
  }

  public sendCommand200(command: Command200): Observable<boolean> {
    return this.http.put<boolean>(`${environment.serverUrl}command/200`, command);
  }

  public deletePendingCommand(command: PCommand): Observable<boolean> {
    return this.http.put<boolean>(`${environment.serverUrl}command/delete/${command.type}`, command);
  }

}
